// Soittaa kysymyksessä olevat ohjeäänet ja kuvaäänet. Mahdollistaa kuvien highlightauksen.
// Soittaa kuvan äänen kuvaa klikatessa. Voidaan valita monta kuvaa. Tallentaa kuvat vastauksena.
// -----------------------

var playingQuestionManySound; //soitetaanko ääntä
var playingQuestionManySoundFirstTime; //soitetaanko ensimmäistä iteraatiota

//heti kun dokumentti on valmis
$(document).ready(function() {
    playingQuestionManySound = true; //soitetaan ohjeäänet, joten true
    playingQuestionManySoundFirstTime = true;

    //etsitään ohjeäänet, ovat div-containerissa answer-many-playlist audio-tagien sisällä
    var playlistContainer = document.getElementById("answer-many-playlist");

    //jos ollaan vastauksen muokkaamisessa, ovat div-containerssa answer-many-replalylist
    var replaylistContainer = document.getElementById("answer-many-replaylist");

    // jos näkymässä ei ole playlist-containeria eikä replaylist-containeria
    // ei näkymässä ole mitään soitettavaa, voidaan poistua metodista
    if (playlistContainer == null && replaylistContainer == null) {

        return;
    }

    var sounds;//alustus
    var imageSounds; //alustus
    var answers = []; //alustus

    //jos ollaan kysymyksessä "ensimmäistä kertaa", soitetaan kaikki äänet
    if (playlistContainer != null) {

        //etsitään playlistcontainerin sisältämät audioelementit (ts. ohjeäänet)
        sounds = playlistContainer.getElementsByTagName("audio");

        //etsitään kuvaäänet, ovat div-containerissa image-sound-playlist audiotagien sisällä
        imageSounds = document.getElementById("image-sound-playlist").getElementsByTagName("audio");

        //jos audioelementtejä löytyy, soitetaan elementit
        if (sounds.length > 0) {
            playQuestionManySound(sounds, 0, imageSounds); //kysymyksen ohjeäänet

        } else if (imageSounds.length > 0) { //jos ohjeääniä ei ole, tarkistetaan onko kuvaääniä ja soitetaan ne
            playImageManySound(imageSounds, 0, sounds);

        } else { //muussa tapauksessa
            $('.play-sounds').removeClass('highlight-buttons');  //poistetaan highlight play-napista
            playingQuestionManySound = false; //ei audiota, voidaan antaa käyttäjän suoraan valita kuvia
            playingQuestionManySoundFirstTime = false;
        }

    } else if(replaylistContainer != null) { //katsotaan ollaanko kysymyksen vastauksen muokkausnäkymässä

        //etsitään playlistcontainerin sisältämät audioelementit (ts. ohjeäänet)
        sounds = replaylistContainer.getElementsByTagName("audio");
        answers = highLightPreviousAnswersMany(); //highlightataan edelliset vastaukset

        playingQuestionManySound = false; //ei audiota, voidaan antaa käyttäjän valita kuvia
        playingQuestionManySoundFirstTime = false;
    } else {
        $('.play-sounds').removeClass('highlight-buttons');  //poistetaan highlight play-napista
        playingQuestionManySound = false;
        playingQuestionManySoundFirstTime = false;
    }

    //jos käyttäjä klikkaa kuvaa, highlightataan kuva
    $('.imageholder').on('click', "img", function () {

        //otetaan käyttäjän klikit vastaan vain jos ääntä ei olla soittamassa
        if(!playingQuestionManySound && !playingQuestionManySoundFirstTime) {
            playingQuestionManySound = true; //kuvan mukana on yleensä ääntä, joten muutetaan trueksi

            $(this).toggleClass('answer-picture'); //lisätään/poistetaan normaalikuva
            $(this).toggleClass('selected'); //lisätään/poistetaan highlight

            $selected = $(this).hasClass("selected"); //katsotaan lisättiinkö vai poistettiinko highlight

            var image = this.getAttribute('value'); //valitun imagen value
            var imageId = this.id;

            //jos tehtiin valinta (ei poistettu)
            if ($selected) {
                //tallennetaan vastaus taulukkoon
                answers.push(image);

                var soundName = "sound"; //äänielementin id on dokumentissa muotoa sound + kuvan id, jolle kuuluu
                soundName = soundName.concat(imageId); //katenoidaan sound + kuvanumero, jolloin saadaan oikea äänen id

                var sound = document.getElementById(soundName); //etsitään valitun kuvan ääni

                if (sound != null) { //jos tulos ei ole null, ääni löytyi
                    sound.play(); //soitetaan ääni

                    //kun äänen soitto lakkaa, asetetaan playingQuestionManySound falseksi, jolloin käyttäjä voi taas valita
                    $(sound).on('ended', function () {
                        $(sound).off('ended');
                        playingQuestionManySound = false;
                        playingQuestionManySoundFirstTime = false;
                    });
                } else {
                    //ääni oli null -> kuvalla ei ole ääntä, käyttäjä voi valita uuden kuvan
                    playingQuestionManySound = false;
                    playingQuestionManySoundFirstTime = false;
                }
                //jos taas valinta poistettiin
            } else {
                //poistetaan taulukosta käyttäjän valinta
                for (var i=0; i<answers.length; i++) { //käydään taulukko läpi
                    var index = answers.indexOf(image); //etsitään samalla arvolla oleva arvo
                    if (index > -1) {
                        answers.splice(index, 1); //poistetaan arvo taulukosta
                    }
                }
                //ei olla soittamassa ääniä, joten voidaan taas hyväksyä käyttäjän syöte
                playingQuestionManySound = false;
                playingQuestionManySoundFirstTime = false;
            }
        }
    });

    //jos käyttäjä painaa submit valinnan jälkeen, lisätään vastaukseksi valitun kuvan id
    var form = document.getElementById('answer-form');
    form.addEventListener("submit", function () {
        //katenoidaan käyttäjän vastaus taulukosta yhdeksi tekstiksi
        var answer = '';
        for(var i=0; i<answers.length; i++) {
            answer = answers[i] + "," + answer;
        }

        var input = document.createElement('input');
        input.type = 'hidden';
        input.name = 'answer';
        input.value = answer;
        this.appendChild(input); //lähetetään vastaus
    }, true);

    //lisätään event-listener play-napille uudelleentoistoa varten
    replayQuestionManyImages(sounds);

});

/**
 * Luo eventin replay-question painikkeelle. Painamalla
 * soittaa uudestaan kysymyksen ohjeäänet.
 *
 * @param sounds
 */
function replayQuestionManyImages(sounds)
{
    $('.replay-question').on('click', function () {
        //otetaan käyttäjän klikit vastaan vain jos ääntä ei olla soittamassa
        if(!playingQuestionManySound) {
            playingQuestionManySound = true; //ollaan soittamassa ääntä, joten true
            if (sounds.length > 0) {
                playQuestionManySound(sounds, 0, 0); //soitetaan ohjeäänet
            } else { //jos ohjeääniä ei ole

                playingQuestionManySound = false; //ei olla soittamassa ääntä
                playingQuestionManySoundFirstTime = false;
            }
        }
    });
}


/**
 * Highlightaa edeltävän vastauksen ja tallentaa
 * edeltävät vastaukset taulukkoon
 *
 * @returns {*}
 */
function highLightPreviousAnswersMany()
{
    var previousAnswer = document.getElementsByClassName('previousManyAnswer');
    var answer = previousAnswer[0].getAttribute('id');
    var answers = [];

    var splittedAnswer = answer.split(",");

    var images = document.getElementsByTagName('img');

    for (var i = 0; i < images.length; i++) {
        for (var j = 0; j < splittedAnswer.length; j++) {

            if(splittedAnswer[j] == images[i].getAttribute('value')) {
                images[i].className = "";
                images[i].className = "selected";
                answers.push(splittedAnswer[j]);
            }
        }
    }

    return answers;
}

/**
 * Funktio, joka soittaa kysymyksen ohjeäänet
 * Ottaa parametrikseen taulukon, jossa ovat äänet ja iteraatio-luvun
 *
 * @param sounds Äänet sisältävä taulukko
 * @param i Iteraatio
 * @param imageSounds Kuvaäänet sisältävä taulukko
 */
function playQuestionManySound(sounds, i, imageSounds)
{
    //odotetaan äänien välillä 2 sek
    setTimeout(function(){
        playingQuestionManySound = true;
        playingQuestionManySoundFirstTime = true;

        sounds[i].play(); //soitetaan ääni

        // Selain esti äänen toiston
        if (sounds[i].paused) {
            playingQuestionManySound = false; //otetaan käyttäjän klikkaukset vastaan
            $("#push-this").show(); //näytetään apunuoli

            //jos käyttäjä klikkaa play-nappia
            $('.play-sounds').on('click', function() {

                //soitetaan äänet vain jos muita ääniä ei jo soiteta
                if(!playingQuestionManySound) {
                    $("#push-this").hide(); //piilotetaan nuoli

                    $('.play-sounds').off('click'); //poistetaan napista kaikki click-eventit
                    playingQuestionManySound = true; //soitetaan ääntä
                    playQuestionManySound(sounds, i, imageSounds);
                }
            }); //play-sounds click event
        }

        //kun ääni on soitettu
        $(sounds[i]).on('ended', function () {
            $(sounds[i]).off('ended');

            i++; //lisätään iteraatiota

            //jos iteraatio menee yli taulukon pituuden, siirrytään kuvaäänien soittoon
            if (i >= sounds.length) {

                //soitetaan kuvaäänet vain jos kuvaääniä on (eli taulukon pituus suurempi kuin 0)
                if (imageSounds.length > 0) {

                    //kutsutaan kuvaäänten soittometodia
                    playImageManySound(imageSounds, 0, sounds);
                } else {
                    $('.play-sounds').removeClass('highlight-buttons');  //poistetaan highlight play-napista
                    $('.play-sounds').off('click'); //poistetaan napista kaikki click-eventit
                    $('.play-sounds').addClass('replay-question'); //lisätään napille uusi luokka (replay)
                    $('.play-sounds').removeClass('play-sounds'); //poistetaan vanha play-sounds luokka

                    replayQuestionManyImages(sounds); //voidaan soittaa ohjeääni uudestaan (lisätään click-event)

                    playingQuestionManySound = false;
                    playingQuestionManySoundFirstTime = false;
                }

                return;
            }

            //jos ei mennä if-lauseeseen, soitetaan seuraava ääni taulukosta
            playQuestionManySound(sounds, i, imageSounds);
        });
    }, 1000);
}

/**
 * Kuvaäänten soitto
 *
 * @param imageSounds Kuvaäänet sisältävä taulukko
 * @param i Iteraatio, lähtee 0:sta
 * @param sounds Ohjeäänet sisältävä taulukko
 */
function playImageManySound(imageSounds, i, sounds)
{
    //odotetaan äänten soiton välillä 2 sek
    setTimeout(function(){
        playingQuestionManySound = true;

        imageSounds[i].play(); //soitetaan ääni

        // Selain esti äänen toiston
        if (imageSounds[i].paused) {
            playingQuestionManySound = false; //otetaan klikit vastaan
            $("#push-this").show(); //näytetään apunuoli

            //jos käyttäjä klikkaa play-nappia
            $('.play-sounds').on('click', function() {
                $('.play-sounds').off('click'); //poistetaan napista kaikki click-eventit
                $("#push-this").hide(); //piilotetaan nuoli
                playingQuestionManySound = true; //soitetaan ääntä
                playImageManySound(imageSounds, i, sounds);
            });
        }

        $('#'+imageSounds[i].id).on('play', function () { //jos ääni soi,highlight
            if(!$('#'+imageSounds[i].id).hasClass('disabled')) {
                highlightImage(imageSounds[i].id); //highlightataan kuva, jolla ääni on (annetaan äänen id parametrina)
            }
            $('#'+imageSounds[i].id).off('play'); //poistetaan event listener
        });

        //kun ääni loppuu
        $(imageSounds[i]).on('ended', function () {

            $(imageSounds[i]).off('ended');

            i++; //lisätään iteraatiota

            //poistetaan kaikista highlight
            $('.selected').addClass('answer-picture');
            $('.selected').removeClass('selected');

            //jos iteraatio suurempi kuin taulukon pituus, ei ole enää soitettavia ääniä
            if (i>= imageSounds.length) {
                $('.play-sounds').removeClass('highlight-buttons');  //poistetaan highlight play-napista
                $('.play-sounds').off('click'); //poistetaan napista kaikki click-eventit
                $('.play-sounds').addClass('replay-question'); //lisätään napille uusi luokka (replay)
                $('.play-sounds').removeClass('play-sounds'); //poistetaan vanha play-sounds luokka

                replayQuestionManyImages(sounds); //voidaan soittaa ohjeääni uudestaan (lisätään click-event)

                playingQuestionManySound = false;
                playingQuestionManySoundFirstTime = false;

                for(i = 0; i < imageSounds.length; i++) {
                    var sound = $('#'+imageSounds[i].id);
                    sound.addClass('disabled');
                }

                return; //palataan
            }

            //jos iteraatio ei ole suurempi, soitetaan seuraava kuvaääni
            playImageManySound(imageSounds, i, sounds);
        });
    }, 500);
}

/**
 * Kuvan korostus ääniä soittaessa
 *
 * @param imageId Kuvan ja äänen id-numero tageissa (sama)
 */
function highlightManyImage(imageId)
{
    var splitString = imageId.match(/.{1,5}/g); //jaetaan string, jotta saadaan numero erilleen
    var imageName = splitString[1]; //otetaan äänen id talteen (ääni = sound+numero, kuva pelkkä numero)

    //etsitään kuva, joka highlightataan
    var image = $('#'+imageName);

    //lisätään kuvalle selected-luokka, jolloin kuva korostuu
    image.removeClass('answer-picture');
    image.addClass('selected');
}
