// Heiluttaa push-this id:llä olevaa diviä ylös ja alas
// (käytetään kuva- ja äänikysymyksissä herättämään käyttäjän huomio play-nappiin)
// -----------------------

function shakeArrow()
{
    var interval = 100; //intervalli
    var moveUp = 10; //paljonko siirretään ylös
    var moveDown = -10; //paljonko siirretään alas

    for (var i = 0; i < (10) ; i++) { //heilutetaan 10 kertaa
        $("#push-this").animate({ //animaatio
            top: ((i % 2 == 0 ? moveUp : moveDown)) //joka toisella siirto ylös, joka toisella alas
        }, interval);
    }
}
