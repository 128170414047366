// Listaa valitussa albumissa olevat äänet alasvetovalikkoon
// -----------------------
$(function() {
    $('#sound_album').on('change', function (e) {

        //otetaan talteen käyttäjän tekemä valinta
        var album_id = e.target.value;

        //haetaan ajaxilla kyseisen albumin äänet
        $.get('/ajax-subsound?album_id=' + album_id, function(data) {

            $('.sound-list').empty(); //tyhjennetään lista

            //lisätään vaihtoehdot listaan
            $.each(data, function(index, subcatObj){
                $('.sound-list').append('<option value="'+subcatObj.id+'">'+subcatObj.sound+'</option>')
            });
        });

    });
});
