// Filtteröi kkysymysteeman muokkaus -sivulla olevat kysymykset
// käyttäjän syötteen perusteella. Näkyviin jätetään vain ne,
// jotka täsmäävät käyttäjän ehtoihin. Lopuksi taulukko vielä väritetään
// raidalliseksi.
// -----------------------

$(function() {

    // käyttäjän valinta
    $('.filter-questions').on('change', function () {

        //asetetaan kaikki käyttäjän syötteet pieniksi kirjaimiksi
        $question = new RegExp($('#select-question-question').val());
        $questionLength = $('#select-question-question').val().length;
        $answerType = $('#select-question-answertype').val().toLowerCase();
        $questionType = $('#select-question-questiontype').val().toLowerCase();
        $targetRole = $('#select-question-targetrole').val().toLowerCase();

        var questionColumn; //alustus
        var answerTypeColumn; //alustus
        var questionTypeColumn; //alustus
        var targetRoleColumn; //alustus

        //poistetaan taulusta raidoitukset
        $('#groupsession-questions-table').removeClass('table-striped');

        var table = document.getElementById('groupsession-questions-table'); //haetaan haluttu taulu

        //asetetaan kaikki rivit näkyviin
        for (var j = 1; j < table.rows.length; j++) {
            table.rows[j].style.display = '';
        }

        //käydään taulu läpi toisesta rivistä alkaen
        //ensimmäisellä rivillä ovat hakuehdot, niitä ei haluta muokata
        for (var i = 1; i < table.rows.length; i++) {

            questionColumn = table.rows[i].cells[1].innerHTML.replace(/<[^>]+>/g, ""); //kysymykset sisältävä
            answerTypeColumn = table.rows[i].cells[2].innerHTML.replace(/<[^>]+>/g, ""); //vastaustyypit
            questionTypeColumn = table.rows[i].cells[3].innerHTML.replace(/<[^>]+>/g, ""); //kysymystyypit
            targetRoleColumn = table.rows[i].cells[4].innerHTML.replace(/<[^>]+>/g, ""); //kohderoolit

            //jos kaikki hakuehdot ovat tyhjiä, voidaan näyttää kaikki rivit
            if ($question.length == 0 && $answerType.length == 0 &&
                $questionType.length == 0 && $targetRole.length == 0) {

                table.rows[i].style.display = ''; //asetetaan näkyciin

                //muussa tapauksessa oli hakuehtoja
            } else {

                //jos hakuehdoissa oli kysymyshakua
                if ($questionLength > 0) {

                    //piilotetaan rivit, joilta kyseistä termiä ei löydy
                    if (!($question.test(questionColumn.toLowerCase()))) {

                        table.rows[i].style.display = 'none'; //piilotus
                    }
                }

                //jos hakuehdoissa oli vastaustyyppi
                if ($answerType.length > 0) {

                    //piilotetaan rivi, jos hakuehdon pituus ja sana eivät täsmää
                    if (answerTypeColumn.toLowerCase().indexOf($answerType) == -1 ||
                        answerTypeColumn.toLowerCase().length != $answerType.length) {

                        table.rows[i].style.display = 'none';
                    }
                }

                //jos hakuehdoissa oli kysymystyyppi
                if ($questionType.length > 0) {

                    //piilotetaan rivi, jos hakuehdon pituus ja sana eivät täsmää
                    if (questionTypeColumn.toLowerCase().indexOf($questionType) == -1 ||
                        questionTypeColumn.toLowerCase().length != $questionType.length) {

                        table.rows[i].style.display = 'none';
                    }
                }

                //jos hakuehdoissa oli kohderooli
                if ($targetRole.length > 0) {

                    //piilotetaan rivi, jos hakuehdon pituus ja sana eivät täsmää
                    if (targetRoleColumn.toLowerCase().indexOf($targetRole) == -1 ||
                        targetRoleColumn.toLowerCase().length != $targetRole.length) {

                        table.rows[i].style.display = 'none';
                    }
                }
            }

        } //for table-length

        //lisätään taulukkoon raidoitukset (löytyy tiedostosta addStripesToTable.js)
        addStripesToTable(table);

    });

    //käyttäjä syötti hakusanan
    $('.filter-question-search').keyup(function() {
        
        //asetetaan kaikki käyttäjän syötteet pieniksi kirjaimiksi
        $question = new RegExp($('#select-question-question').val());
        $answerType = $('#select-question-answertype').val().toLowerCase();
        $questionType = $('#select-question-questiontype').val().toLowerCase();
        $targetRole = $('#select-question-targetrole').val().toLowerCase();

        $questionLength = $('#select-question-question').val().length; //katsotaan hakusanan pituus

        var questionColumn; //alustus
        var answerTypeColumn; //alustus
        var questionTypeColumn; //alustus
        var targetRoleColumn; //alustus

        //poistetaan taulusta raidoitukset
        $('#groupsession-questions-table').removeClass('table-striped');

        var table = document.getElementById('groupsession-questions-table'); //haetaan haluttu taulu

        //asetetaan kaikki rivit näkyviin
        for (var j = 1; j < table.rows.length; j++) {
            table.rows[j].style.display = '';
        }

        //käydään taulu läpi toisesta rivistä alkaen
        //ensimmäisellä rivillä ovat hakuehdot, niitä ei haluta muokata
        for (var i = 1; i < table.rows.length; i++) {

            questionColumn = table.rows[i].cells[1].innerHTML.replace(/<[^>]+>/g, ""); //kysymykset sisältävä
            answerTypeColumn = table.rows[i].cells[2].innerHTML.replace(/<[^>]+>/g, ""); //vastaustyypit
            questionTypeColumn = table.rows[i].cells[3].innerHTML.replace(/<[^>]+>/g, ""); //kysymystyypit
            targetRoleColumn = table.rows[i].cells[4].innerHTML.replace(/<[^>]+>/g, ""); //kohderoolit

            //jos kaikki hakuehdot ovat tyhjiä, voidaan näyttää kaikki rivit
            if ($question.length == 0 && $answerType.length == 0 &&
                $questionType.length == 0 && $targetRole.length == 0) {

                table.rows[i].style.display = ''; //asetetaan näkyciin

                //muussa tapauksessa oli hakuehtoja
            } else {

                //jos hakuehdoissa oli kysymyshakua
                if ($questionLength > 0) {

                    //piilotetaan rivit, joilta kyseistä termiä ei löydy
                    if (!($question.test(questionColumn.toLowerCase()))) {

                        table.rows[i].style.display = 'none'; //piilotus
                    }
                }

                //jos hakuehdoissa oli vastaustyyppi
                if ($answerType.length > 0) {

                    //piilotetaan rivi, jos hakuehdon pituus ja sana eivät täsmää
                    if (answerTypeColumn.toLowerCase().indexOf($answerType) == -1 ||
                        answerTypeColumn.toLowerCase().length != $answerType.length) {

                        table.rows[i].style.display = 'none';
                    }
                }

                //jos hakuehdoissa oli kysymystyyppi
                if ($questionType.length > 0) {

                    //piilotetaan rivi, jos hakuehdon pituus ja sana eivät täsmää
                    if (questionTypeColumn.toLowerCase().indexOf($questionType) == -1 ||
                        questionTypeColumn.toLowerCase().length != $questionType.length) {

                        table.rows[i].style.display = 'none';
                    }
                }

                //jos hakuehdoissa oli kohderooli
                if ($targetRole.length > 0) {

                    //piilotetaan rivi, jos hakuehdon pituus ja sana eivät täsmää
                    if (targetRoleColumn.toLowerCase().indexOf($targetRole) == -1 ||
                        targetRoleColumn.toLowerCase().length != $targetRole.length) {

                        table.rows[i].style.display = 'none';
                    }
                }
            }

        } //for table.length

        //lisätään taulukkoon raidoitukset (löytyy tiedostosta addStripesToTable.js)
        addStripesToTable(table);
    });
});
