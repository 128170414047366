// Valitsee kaikki näkyvissä olevat kysymykset kyselyn "Lisäämättömät kysymykset"-välilehdellä.
// -----------------------

/**
 * Kyselyt-sivulla oleva checkbox on luokaltaan select-all-visible.
 * Kun se ruksitaan, valitaan kaikki näkyvissä olevat kysymykset.
 * Kun ruksi otetaan pois, otetaan ruksi kaikista pois.
 */
$(".select-all-visible").change(function() {

    var table = document.getElementById('available-questions-table'); //haetaan haluttu taulu

    //käydään taulu läpi
    for (var i = 1; i < table.rows.length; i++) {

        //jos rivi on näkyvissä voidaan kysymys valita
        if (table.rows[i].style.display != 'none') {

            //haetaan kysymyksen id
            var questionId = table.rows[i].cells[1].innerHTML;

            //kysymyksen checkboxilla on id, jonka arvo on #kysymysId
            $checkBox = $(questionId);

            //lisätään kyseisen kysymyksen checkboxille luokka question-checkbox
            $checkBox.addClass('question-checkbox');
        } //if
    }//for

    //jos checkboxiin ilmestyi ruksi
    if(this.checked) {

        //käydään läpi kaikki, joilla luokka question-checkbox (eli näkyvissä olevat)
        $('.question-checkbox').each(function() {
            this.checked = true;  //asetetaan ne valituiksi
        });

    //muussa tapauksessa valinta poistettiin
    }else{

        //käydään läpi kaikki sivun checkboxit (luokkana remove-check)
        $('.remove-check').each(function() {
            this.checked = false; //otetaan kaikista valinta pois

            var id = this.id; //otetaan valinnan id talteen

            //tarkistetaan onko valinnalla question-checkbox luokka, jos on, poistetaan se
            if ($('#' + id).hasClass('question-checkbox')) {

                $('#' + id).removeClass('question-checkbox');
            }
        }); //remove-check event
    } //if-else
});
