// Näyttää latausindikaattorit tiedostojen latauksessa
//
// -----------------------


$(document).ready(function () {
    $('#download-answers').submit(function () { //kun haetaan vastauksia
        $('.loader').show(); //näytetään loader
        disableUI(); //dissabloidaan ui
        showIndicators(); //katsotaan onko tiedosto jo ladattu
    });

    $('.show-indicators').on('click', function() {
        $('.loader').show(); //näytetään loader
        disableUI(); //dissabloidaan ui
    });

    $('#show-copy-indicators').submit(function () { //kun haetaan vastauksia
        $('.loader').show(); //näytetään loader
        disableUI(); //dissabloidaan ui
    });
});

var fileDownloadCheckTimer; //timer alustus

/**
 * Kuunnellaan tulevia keksejä. Jos saapuu download_is_finished, tiedosto on tullut.
 */
function showIndicators()
{
    //tarkistetaan keksit sekunnin välein
    fileDownloadCheckTimer = window.setInterval(function () {

        var cookieJar = document.cookie.split(';'); //haetaan kaikki keksit

        for(var i = 0; i <cookieJar.length; i++) { //käydään keksit läpi
            var cookieName = cookieJar[i].split('='); //haetaan keksin nimi

            //jos nimi mätsää download_is_finished, on tiedosto tullut perille ja voidaan poistaa indikaattorit
            if (cookieName[0] == ' download_is_finished') {
                finishDownload();
            }
        }
    }, 1000);
}

/**
 * Lopetetaan lataus, eli poistetana intervallikuuntelu, enabloidaan UI ja poistetaan loaderi näkyvistä.
 */
function finishDownload()
{
    window.clearInterval(fileDownloadCheckTimer); //poistetaan intervalli
    $('.loader').hide(); //piilotetaan loaderit
    enableUI(); //enabloidaan ui
}

/**
 * Lisää sivun päälle harmaan laatikon, jolloin ei voida klikata mitään
 */
function disableUI()
{
    $("body").append('<div id="overlay" style="' +
        'background-color: grey;' +
        'position: absolute;' +
        'top: 0;' +
        'left: 0;' +
        'height: 100%;' +
        'width: 100%;' +
        'z-index: 998; ' +
        'opacity: 0.5;' +
        '"></div>');
    $("#overlay").width($(document).width()); //asetetaan koko dokumentin leveys
    $("#overlay").height($(document).height()); //asetetaan koko dokumentin korkeus
}

/**
 * Poistetaan disabloiva div
 */
function enableUI()
{
    $("#overlay").remove();
}
