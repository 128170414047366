// Tallentaa valitun välilehden, jotta muistetaan valinta kun sivulle tullaan uudestaan
// -----------------------

// ottaa sivun osoitteen ja asettaa keksit
var getAddress = function () {
    var addressValue = $(this).attr("href");
    setCookie(window.location.href, addressValue);
};

// Asettaa välilehden oikeaksi
$('.nav-tabs a[href="' + getCookie(window.location.href) + '"]').tab('show');

// Tallentaa uuden välilehden arvon
$('.nav-tabs').on('click', 'a', getAddress);

//asettaa cookiesit, jonka avulla valittu välilehti näytetään
function setCookie(cookieName, cookieValue) {
    //ei aseteta kekseille expiredatea, niin tuhoutuvat selaimen sulkemisen yhteydessä
    //luodaan dokumentille keksi
    document.cookie = cookieName + "=" + cookieValue + "; ";
}

//palauttaa cookiesin, jos on
function getCookie(cookieName) {
    var name = cookieName + "=";
    var cookieArray = document.cookie.split(';');
    for (var i = 0; i < cookieArray.length; i++) {
        var cookie = cookieArray[i];
        while (cookie.charAt(0) == ' ') {
            cookie = cookie.substring(1);
        }
        if (cookie.indexOf(name) == 0) {

            return cookie.substring(name.length, cookie.length);
        }
    }

    return "";
}

