// Soittaa kysymyksessä olevat ohjeäänet ja kuvaäänet.
// Käyttäjä ei voi valita kuvia (ts. orientoiva kysymys).
// -----------------------

var playingInstructions;

//heti kun dokumentti on valmis
$(document).ready(function() {
    playingInstructions = true; //soitetaan ääniä

    //etsitään ohjeäänet, ovat div-containerissa answer-static-playlist audio-tagien sisällä
    var playlistContainer = document.getElementById("answer-static-playlist");
    var imageSoundContainer = document.getElementById("image-static-sound-playlist");

    //jos näkymässä ei ole playlist- tai imageSounds (kuvaääniä), voidaan poistua kokonaan (ei ole ääniä joita soittaa)
    if (playlistContainer == null) {
        if(imageSoundContainer == null) {
            playingInstructions = false; //ei soiteta ääniä, ääniä ei löytynyt

            return; //palataan
        }
    }

    var sounds; //alustus
    var imageSounds; //alustus
    var answer =''; //alustus

    if (playlistContainer != null) {
        //etsitään playlistcontainerin sisältämät audioelementit (ts. ohjeäänet)
        sounds = playlistContainer.getElementsByTagName("audio");

        //etsitään kuvaäänet, ovat div-containerissa image-sound-playlist audiotagien sisällä
        imageSounds = imageSoundContainer.getElementsByTagName("audio");

        //jos audioelementtejä löytyy, soitetaan elementit
        if (sounds.length > 0) {
            playStaticQuestionSound(sounds, 0, imageSounds); //kysymyksen ohjeäänet

        } else if (imageSounds.length > 0) { //jos ohjeääniä ei ole, tarkistetaan onko kuvaääniä ja soitetaan ne
            playStaticImageSound(imageSounds, 0);

        } else {
            removeHighlightFromPlay(); //poistaa highlightin play-napista
            playingInstructions = false; //ei löytynyt ääniä
        }

    } else if(imageSoundContainer != null) { //jos ei ole ohjeääniä, mutta on kuvaääniä

        //etsitään kuvaäänet, ovat div-containerissa image-sound-playlist audiotagien sisällä
        imageSounds = imageSoundContainer.getElementsByTagName("audio");

        //jos audioelementtejä lyötyy, soitetaan ne, muussa tapauksessa hyväksytään käyttäjän klikkailut
        if (imageSounds.length > 0) {
            playStaticImageSound(imageSounds, 0);
        } else {
            removeHighlightFromPlay();
            playingInstructions = false; //hyväksytään klikit
        }
    }
    else {
        removeHighlightFromPlay();
        playingInstructions = false;
    }
});

function replayStaticQuestion(sounds, imageSounds)
{
    //soitetaan uudestaan ohjeäänet, käyttäjä painoi kysymysmerkkiä
    $('.replay-question').on('click', function () {
        //otetaan käyttäjän klikit vastaan vain jos ääntä ei olla soittamassa
        if(!playingInstructions) {

            playingInstructions = true; //ollaan soittamassa ääntä, joten ei voida painella

            if (sounds != undefined && sounds.length > 0) { //tarkistetaan onko ohjeäänet asetettu
                playStaticQuestionSound(sounds, 0, imageSounds); //soitetaan ohjeäänet
            } else if(imageSounds != undefined && imageSounds.length > 0) { //onko kuvaäänet asetettu
                playStaticImageSound(imageSounds, 0); //soitetaan kuvaäänet
            } else { //jos ääniä ei ole
                playingInstructions = false; //ei olla soittamassa ääntä, hyväksytään painallukset
            }
        }
    });
}

/**
 * Poistaa playnapista highlightin
 */
function removeHighlightFromPlay()
{
    if($('.replay-question').hasClass("highlight-buttons")) {

        $('.replay-question').removeClass("highlight-buttons");
    }
}

/**
 * Funktio, joka soittaa kysymyksen ohjeäänet.
 * Ottaa parametrikseen taulukon, jossa ovat äänet, iteraatio luvun ja kuvaäänten taulukon.
 *
 * @param sounds Äänet sisältävä taulukko
 * @param i Iteraatio
 * @param imageSounds Kuvaäänet sisältävä taulukko
 */
function playStaticQuestionSound(sounds, i, imageSounds)
{
    //odotetaan äänien välillä 2 sek
    setTimeout(function(){
        playingInstructions = true; //ollaan soittamassa ääniä

        sounds[i].play(); //soitetaan ääni

        // Selain esti äänen toiston
        if (sounds[i].paused) {
            playingInstructions = false; //otetaan klikkaukset vastaan
            $("#push-this").show(); //näytetään apunuoli

            //jos käyttäjä klikkaa play-nappia
            $('.replay-question').on('click', function() {
                $('.replay-question').off('click'); //poistetaan napista kaikki click-eventit
                $("#push-this").hide(); //piilotetaan nuoli
                playingInstructions = true; //soitetaan ääntä
                playStaticQuestionSound(sounds, i, imageSounds);
            });
        }

        $(sounds[i]).on('play', function () { //jos ääni soi,highlight
            $('.replay-question').off('click'); //poistetaan napista kaikki click-eventit
            $(sounds[i]).off('play'); //poistetaan event listener
        });

        //kun ääni on soitettu
        $(sounds[i]).on('ended', function () {
            $(sounds[i]).off('ended');

            i++; //lisätään iteraatiota

            //jos iteraatio menee yli taulukon pituuden, siirrytään kuvaäänien soittoon
            if (i>= sounds.length) {

                //soitetaan kuvaäänet vain jos kuvaääniä on (eli taulukon pituus suurempi kuin 0)
                if (imageSounds.length > 0) {

                    //kutsutaan kuvaäänten soittometodia
                    playStaticImageSound(imageSounds, 0, sounds);
                } else {
                    removeHighlightFromPlay();
                    playingInstructions = false; //käyttäjä voi klikkailla
                    replayStaticQuestion(sounds, imageSounds);
                }
            } else {
                //jos ei mennä if-lauseeseen, soitetaan seuraava ääni taulukosta
                playStaticQuestionSound(sounds, i, imageSounds);
            }
        });
    }, 1000);
}

/**
 * Kuvaäänten soitto
 *
 * @param sounds Kuvaäänet sisältävä taulukko
 * @param i Iteraatio, lähtee 0:sta
 * @param questionSounds Kysymysäänet sisältä taulu
 */
function playStaticImageSound(sounds, i, questionSounds)
{
    //odotetaan äänten soiton välillä 2 sek
    setTimeout(function(){
        playingInstructions = true; //kuvia ei voida vielä valita

        sounds[i].play(); //soitetaan ääni

        // Selain esti äänen toiston
        if (sounds[i].paused) {
            playingInstructions = false; //otetaan klikkaukset vastaan
            $("#push-this").show(); //näytetään apunuoli

            //jos käyttäjä klikkaa play-nappia
            $('.replay-question').on('click', function() {
                $('.replay-question').off('click'); //poistetaan napista kaikki click-eventit
                $("#push-this").hide(); //piilotetaan nuoli
                playingInstructions = true; //soitetaan ääntä
                playStaticImageSound(sounds, i, questionSounds);
            });
        } else {
            highlightStaticImage(sounds[i].id); //highlightataan kuva, jolla ääni on (annetaan äänen id parametrina)
        }

        $(sounds[i]).on('play', function () { //jos ääni soi,highlight
            $('.replay-question').off('click'); //poistetaan napista kaikki click-eventit
            highlightStaticImage(sounds[i].id); //highlightataan kuva, jolla ääni on (annetaan äänen id parametrina)
            $(sounds[i]).off('play'); //poistetaan event listener
        });


        //kun ääni loppuu
        $(sounds[i]).on('ended', function () {
            $(sounds[i]).off('ended');

            i++; //lisätään iteraatiota

            //poistetaan kaikista highlight
            $('.selected').addClass('static-answer-picture');

            //poistetaan kaikista highlight
            $('.selected').removeClass('selected');

            //jos iteraatio suurempi kuin taulukon pituus, ei ole enää soitettavia ääniä
            if (i >= sounds.length) {
                removeHighlightFromPlay();
                playingInstructions = false; //asetetaan valinta trueksi, käyttäjä voi klikkailla
                replayStaticQuestion(questionSounds, sounds);
            } else {
                //jos iteraatio ei ole suurempi, soitetaan seuraava kuvaääni
                playStaticImageSound(sounds, i, questionSounds);
            }
        });
    }, 500);
}

/**
 * Kuvan korostus ääniä soittaessa
 *
 * @param imageId Kuvan ja äänen id-numero tageissa (sama)
 */
function highlightStaticImage(imageId)
{
    var splitString = imageId.match(/.{1,5}/g); //jaetaan string, jotta saadaan numero erilleen
    var imageName = splitString[1]; //otetaan äänen id talteen (ääni = sound+numero, kuva pelkkä numero)

    //etsitään kuva, joka highlightataan
    var image = $('#'+imageName);

    //lisätään kuvalle selected-luokka, jolloin kuva korostuu
    image.removeClass('static-answer-picture');
    image.addClass('selected');
}
