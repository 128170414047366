// Listaa valitussa albumissa olevat kuvat alasvetovalikkoon
// -----------------------
$(function() {
    $('#image_album').on('change', function (e) {

        //otetaan talteen käyttäjän tekemä valinta
        var album_id = e.target.value;

        //haetaan kyseisellä albumin id:llä olevat kuvat ajaxilla
        $.get('/ajax-subimage?album_id=' + album_id, function(data) {

            $('.image-list').empty(); //tyhjennetään vanha lista

            //lisätään vaihtoehdot valikkoon
            $.each(data, function(index, subcatObj){
                $('.image-list').append('<option value="'+subcatObj.id+'">'+subcatObj.image+'</option>')
            });
        });
    });
});
