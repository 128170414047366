// Varmistaa haluaako käyttäjä poistua sivulta vaikka lomaketta muokattu
// -----------------------
$(document).ready(function() {
    formWasModified = false; //totuusarvo, jolla pidetään kirjaa onko lomaketta muokattu

    $('.promptable').change(function(){ //kysymykset ovat div-containerin sisällä, jonka id questions_form
        formWasModified = true; //muutetaan totuusarvo
    });

    window.onbeforeunload = confirmExit; //ennen kuin poistutaan sivulta

    //varmistuksen tapahtuminen
    function confirmExit() {
        if (formWasModified == true) { //jos totuusarvo true tehdään varmistus

            return "Et ole tallentanut lomaketta. Haluatko varmasti poistua sivulta?";

        }
    }

    //varmistusta ei haluta tapahtuvan, jos käyttäjä tallentaa tai poistaa lomakkeen (class on ignoreConfirm)
    $(".ignoreConfirm").click(function() {
        formWasModified = false;
    });
});
