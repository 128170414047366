// Soittaa kuvakollaasin vastauskoosteessa olevat koosteäänet
//
// -----------------------


$(document).ready(function(){

    //etsitään container, jonka sisällä äänet ovat
    var summaryContainer = document.getElementById("answer-many-summarylist");

    //jos näkymässä ei ole containeria, voidaan lopettaa tähän
    if (summaryContainer == null) {

        return;
    }

    var sounds = summaryContainer.getElementsByTagName("audio");

    if(sounds.length > 0) {

        playSummarySounds(sounds, 0);
    } else {

        removeHighlightFromSummary(sounds);
    }
});

/**
 * Poistaa playnapista highlightin
 */
function removeHighlightFromSummary(sounds)
{
    $('.play-sounds').off('click');

    //lisätään replay-class
    $('.play-sounds').addClass('replay-question');

    //ei enää soiteta ääniä, poistetaan korotus soittonapista
    $('.play-sounds').removeClass('highlight-buttons');

    //lisätään replaySoundsEventListener
    replaySummarySounds(sounds);
}

/**
 * Soittaa näkymän äänet
 *
 * @param sounds Näkymän äänet
 * @param i Iteraatio
 */
function playSummarySounds(sounds, i) {
//odotetaan äänien välillä 2 sek
    setTimeout(function(){

        sounds[i].play(); //soitetaan ääni

        // Selain esti äänen toiston
        if (sounds[i].paused) {
            $("#push-this").show(); //näytetään apunuoli

            //jos käyttäjä klikkaa play-nappia
            $('.play-sounds').on('click', function() {
                sounds[i].play();
                $("#push-this").hide(); //piilotetaan nuoli

                $('.play-sounds').off('click'); //poistetaan napista kaikki click-eventit
            }); //play-sounds click event
        } //if sounds[i].paused

        //kun ääni on soitettu
        $(sounds[i]).on('ended', function () {
            $(sounds[i]).off('ended');
            i++; //lisätään iteraatiota

            //jos iteraatio menee yli taulukon pituuden, siirrytään pois
            if (i >= sounds.length) {

                removeHighlightFromSummary(sounds);

                return;
            }

            //jos ei mennä if-lauseeseen, soitetaan seuraava ääni taulukosta
            playSummarySounds(sounds, i);
        });
    }, 1000);
}

function replaySummarySounds(sounds) {
    $('.replay-question').on('click', function () {
        if (sounds.length > 0) {
            playSummarySounds(sounds, 0); //soitetaan ohjeäänet
        }
    });
}
