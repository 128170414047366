// YKSITTÄINEN KUVA/ÄÄNI:
// Soittaa kysymyksessä olevat ohjeäänet ja kuvaäänet. Mahdollistaa kuvien highlightauksen.
// Soittaa kuvan äänen kuvaa klikatessa.
// -----------------------

var playingQuestionSound = false; //muuttuja joka kertoo, ollaanko ääniä soittamassa vai ei
var playingOnePictureInstructions = false; //muuttuja joka kertoo ollaanko ensimmäinen iteraatio ääniä soitettu

//heti kun dokumentti on valmis
$(document).ready(function() {
    playingQuestionSound = true; //soitetaan ääniä, kuvia ei voida valita
    playingOnePictureInstructions = true;

    //etsitään ohjeäänet, ovat div-containerissa answer-playlist audio-tagien sisällä
    var playlistContainer = document.getElementById("answer-playlist");

    //jos ollaan vastauksen muokkausnäkymässä, äänet ovat div-containerissa answer-replaylist
    var replaylistContainer = document.getElementById("answer-replaylist");

    //jos näkymässä ei ole playlist- tai replaylistcontaineria, voidaan poistua kokonaan
    if (playlistContainer == null && replaylistContainer == null) {

        return;
    }

    var sounds; //alustus (ohjeäänet)
    var imageSounds; //alustus (kuvaäänet)
    var answer =''; //alustus (vastaus)

    //jos löytyy kysymysääniä
    if (playlistContainer != null) {
        //etsitään playlistcontainerin sisältämät audioelementit (ts. ohjeäänet)
        sounds = playlistContainer.getElementsByTagName("audio");

        //etsitään kuvaäänet, ovat div-containerissa image-sound-playlist audiotagien sisällä
        imageSounds = document.getElementById("image-sound-playlist").getElementsByTagName("audio");

        //jos audioelementtejä löytyy, soitetaan elementit
        if (sounds.length > 0) {

            //soitetaan ohjeäänet
            playQuestionSound(sounds, 0, imageSounds, playingQuestionSound); //kysymyksen ohjeäänet

        } else if (imageSounds.length > 0) { //jos ohjeääniä ei ole, tarkistetaan onko kuvaääniä ja soitetaan ne

            //soitetaan kuvaäänet
            playImageSound(imageSounds, 0, playingQuestionSound);

        } else { //muussa tapauksessa
            $('.play-sounds').removeClass('highlight-buttons'); //poistetaan play-painikkeesta highlight
            $('.play-sounds').off('click'); //poistetaan click-eventit
            $('.play-sounds').addClass('replay-question'); //lisätään play-nappiin replay-question luokka
            $('.play-sounds').removeClass('play-sounds'); //poistetaan play-sounds luokka

            replayQuestionOneImage(sounds); //voidaan soittaa ohjeääni uudestaan (lisätään click-event)

            playingQuestionSound = false; //ei audiota, voidaan antaa käyttäjän suoraan valita kuvia
            playingOnePictureInstructions = false;
        }

        //jos ollaan vastauksen muokkausnäkymässä
    } else if(replaylistContainer != null) {

        //etsitään playlistcontainerin sisältämät audioelementit (ts. ohjeäänet)
        sounds = replaylistContainer.getElementsByTagName("audio");

        //highlightataan edeltävä vastaus
        answer = highLightPreviousAnswer();

        //ääniä ei tarvitse soittaa kun tullaan muokkausnäkymään, joten voidaan suoraan hyväksyä käyttäjän syötettä
        playingQuestionSound = false;
        playingOnePictureInstructions = false;

    } else {
        playingQuestionSound = false;
        playingOnePictureInstructions = false;
    }

    //jos käyttäjä klikkaa kuvaa, highlightataan kuva
    $('.imageholder').on('click', "img", function () {
        if(!playingQuestionSound && !playingOnePictureInstructions) { //highlight vain siinä tapauksessa että playingQuestionSound on false
            playingQuestionSound = true; //soitetaan kuvan ääni, kuvia ei voida valita

            //koska vastauksena on vain yksi kuva, pitää vanhasta valinnasta poistaa highlightin antava
            //luokka ja lisätä vanha luokka
            $('.selected').addClass('answer-picture'); //lisätään kaikkiin joilla selected answer-picture luokka
            $('.selected').removeClass('selected'); //poistetaan kaikista select-luokista selected

            //lisätään nykyiseen valintaan selected ja poistetaan answer-picture
            $(this).removeClass('answer-picture');
            $(this).addClass('selected'); //lisätään valinta klikattuun

            answer = this.getAttribute('value'); //valitun imagen value
            var imageId = this.id;

            var soundName = "sound"; //äänielementin id on dokumentissa muotoa sound + kuvan id, jolle kuuluu
            soundName = soundName.concat(imageId); //katenoidaan sound + kuvanumero, jolloin saadaan oikea äänen id

            var sound = document.getElementById(soundName); //etsitään valitun kuvan ääni

            if (sound != null) { //jos tulos ei ole null, ääni löytyi
                sound.play(); //soitetaan ääni

                //kun äänen soitto lakkaa, asetetaan playingQuestionSound falseksi, jolloin käyttäjä voi taas valita
                $(sound).on('ended', function () {
                    $(sound).off('ended');
                    playingQuestionSound = false;
                });
            } else {
                //ääni oli null -> kuvalla ei ole ääntä, käyttäjä voi valita uuden kuvan
                playingQuestionSound = false; //käyttäjä voi valita kuvia tai painaa replay-nappia
            }
        }
    });

    //jos käyttäjä painaa submit valinnan jälkeen, lisätään vastaukseksi valitun kuvan id
    var form = document.getElementById('answer-form');
    form.addEventListener("submit", function() {
        var input = document.createElement('input');
        input.type = 'hidden';
        input.name = 'answer';
        input.value = answer;
        this.appendChild(input);
    }, true);

    //click-event
    replayQuestionOneImage(sounds);
});

/**
 * Luo click-eventin replay-question -luokalla olevalle painikkeelle
 *
 * @param sounds Ohjeäänet sisältävä taulukko
 */
function replayQuestionOneImage(sounds)
{
    //soitetaan uudestaan ohjeäänet, käyttäjä painoi play-nappia
    $('.replay-question').on('click', function () {

        //otetaan käyttäjän klikit vastaan vain jos ääntä ei olla soittamassa
        if(!playingQuestionSound) {
            playingQuestionSound = true; //ollaan soittamassa ääntä, joten kuvia ei voi valita

            //jos ääniä on
            if (sounds.length > 0) {

                playQuestionSound(sounds, 0, 0); //soitetaan ohjeäänet

            } else { //jos ohjeääniä ei ole

                playingQuestionSound = false; //ei olla soittamassa ääntä, hyväksytään painallukset
            }
        }
    });
}


/**
 * Highlightaa edeltävän vastauksen
 *
 * @returns {*}
 */
function highLightPreviousAnswer()
{
    //vanha vastaus löytyy luokalla previousAnswer
    var previousAnswer = document.getElementsByClassName('previousAnswer');

    var answer = previousAnswer[0].getAttribute('id');

    var images = document.getElementsByTagName('img');

    for (var i = 0; i < images.length; i++) {
        if(answer == images[i].getAttribute('value')) {

            //lisätään selected-luokka vastauksille
            images[i].className = "";
            images[i].className = "selected";

            return answer;
        }
    }

    return null;
}

/**
 * Funktio, joka soittaa kysymyksen ohjeäänet.
 * Ottaa parametrikseen taulukon, jossa ovat äänet, iteraatio luvun ja kuvaäänten taulukon.
 *
 * @param sounds Äänet sisältävä taulukko
 * @param i Iteraatio
 * @param imageSounds Kuvaäänet sisältävä taulukko
 */
function playQuestionSound(sounds, i, imageSounds)
{
    //odotetaan äänien välillä 2 sek
    setTimeout(function(){
        playingQuestionSound = true; //varmistutaan että playingQuestionSound on true
        playingOnePictureInstructions = true;

        sounds[i].play(); //soitetaan ääni

        // Selain esti äänen toiston
        if (sounds[i].paused) {
            playingQuestionSound = false; //otetaan käyttäjän klikkaukset vastaan
            $("#push-this").show(); //näytetään apunuoli

            //jos käyttäjä klikkaa play-nappia
            $('.play-sounds').on('click', function() {

                //soitetaan äänet vain jos muita ääniä ei jo soiteta
                if(!playingQuestionSound) {
                    $("#push-this").hide(); //piilotetaan nuoli

                    $('.play-sounds').off('click'); //poistetaan napista kaikki click-eventit
                    playingQuestionSound = true; //soitetaan ääntä
                    playQuestionSound(sounds, i, imageSounds);
                }
            }); //play-sounds click event
        } //if sounds[i].paused

        //kun ääni on soitettu
        $(sounds[i]).on('ended', function () {
            $(sounds[i]).off('ended');
            i++; //lisätään iteraatiota

            //jos iteraatio menee yli taulukon pituuden, siirrytään kuvaäänien soittoon
            if (i>= sounds.length) {

                //soitetaan kuvaäänet vain jos kuvaääniä on (eli taulukon pituus suurempi kuin 0)
                if (imageSounds.length > 0) {

                    //kutsutaan kuvaäänten soittometodia
                    playImageSound(imageSounds, 0, sounds);

                } else { //ääniä ei ollut

                    $('.play-sounds').removeClass('highlight-buttons');  //poistetaan highlight play-napista
                    $('.play-sounds').off('click'); //poistetaan napista kaikki click-eventit
                    $('.play-sounds').addClass('replay-question'); //lisätään napille uusi luokka (replay)
                    $('.play-sounds').removeClass('play-sounds'); //poistetaan vanha play-sounds luokka

                    replayQuestionOneImage(sounds); //voidaan soittaa ohjeääni uudestaan (lisätään click-event)

                    playingQuestionSound = false; //käyttäjä voi valita kuvia tai painaa replay-nappia
                    playingOnePictureInstructions = false;
                }

                //palataan
                return;
            }

            //jos ei mennä if-lauseeseen, soitetaan seuraava ääni taulukosta
            playQuestionSound(sounds, i, imageSounds);
        });
    }, 1000);
}

/**
 * Kuvaäänten soitto
 *
 * @param imageSounds Kuvaäänet sisältävä taulukko
 * @param i Iteraatio, lähtee 0:sta
 * @param sounds Ohjeäänet sisältävä taulukko
 */
function playImageSound(imageSounds, i, sounds)
{
    //odotetaan äänten soiton välillä 2 sek
    setTimeout(function(){
        playingQuestionSound = true; //kuvia ei voida vielä valita

        imageSounds[i].play(); //soitetaan ääni

        // Selain esti äänen toiston
        if (imageSounds[i].paused) {
            playingQuestionSound = false; //otetaan käyttäjän klikkaukset vastaan
            $("#push-this").show(); //näytetään apunuoli

            //jos käyttäjä klikkaa play-nappia
            $('.play-sounds').on('click', function() {
                $('.play-sounds').off('click'); //poistetaan click-eventit
                $("#push-this").hide(); //piilotetaan nuoli

                playingQuestionSound = true; //soitetaan ääntä
                playImageSound(imageSounds, i, sounds);
            });
        }

        $('#'+imageSounds[i].id).on('play', function () { //jos ääni soi,highlight
            if(!$('#'+imageSounds[i].id).hasClass('disabled')) {
                highlightImage(imageSounds[i].id); //highlightataan kuva, jolla ääni on (annetaan äänen id parametrina)
            }
            $('#'+imageSounds[i].id).off('play'); //poistetaan event listener
        });

        //kun ääni loppuu
        $(imageSounds[i]).on('ended', function () {
            $(imageSounds[i]).off('ended');
            i++; //lisätään iteraatiota

            //poistetaan kaikista highlight
            $('.selected').addClass('answer-picture');
            $('.selected').removeClass('selected');

            //jos iteraatio suurempi kuin taulukon pituus, ei ole enää soitettavia ääniä
            if (i >= imageSounds.length) {

                $('.play-sounds').removeClass('highlight-buttons'); //poistetaan play-painikkeesta highlight
                $('.play-sounds').off('click'); //poistetaan click-eventit
                $('.play-sounds').addClass('replay-question'); //lisätään play-nappiin replay-question luokka
                $('.play-sounds').removeClass('play-sounds'); //poistetaan play-sounds luokka

                replayQuestionOneImage(sounds); //voidaan soittaa ohjeääni uudestaan (lisätään click-event)

                playingQuestionSound = false; //käyttäjä voi valita kuvia tai painaa replay-nappia
                playingOnePictureInstructions = false;

                for(i = 0; i < imageSounds.length; i++) {
                    var sound = $('#'+imageSounds[i].id);
                    sound.addClass('disabled');
                }

                return; //palataan
            }

            //jos iteraatio ei ole suurempi, soitetaan seuraava kuvaääni
            playImageSound(imageSounds, i, sounds);
        });
    }, 500);
}

/**
 * Kuvan korostus ääniä soittaessa
 *
 * @param imageId Kuvan ja äänen id-numero tageissa (sama)
 */
function highlightImage(imageId)
{
    var splitString = imageId.match(/.{1,5}/g); //jaetaan string, jotta saadaan numero erilleen
    var imageName = splitString[1]; //otetaan äänen id talteen (ääni = sound+numero, kuva pelkkä numero)

    //etsitään kuva, joka highlightataan
    var image = $('#'+imageName);

    //lisätään kuvalle selected-luokka, jolloin kuva korostuu
    image.removeClass('answer-picture');
    image.addClass('selected');
}
