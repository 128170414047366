// Soittaa valitun äänen play-painikkeesta. Pysäyttää äänen stop-painikkeesta.
// -----------------------
$(function() {
    $('.play-sound').on('click', function () { //jos käyttäjä painaa fa-play-circle-elementtiä

        var sounds = document.getElementsByTagName('audio'); //otetaan talteen kaikki sivun audioelementissä olevat
        for (var i=0; i<sounds.length; i++) { //käydään ne läpi
            sounds[i].pause(); //pysäytetään niiden soitto, jottei soiteta monta ääntä päällekäin
            if (sounds[i].currentTime != 0) {
                sounds[i].currentTime=0; //alustetaan soimaan alusta (ts. stop ei pause)
            }
        }

        //otetaan talteen käyttäjän tekemä valinta
        var soundId = this.id;
        var sound = document.getElementById(soundId); //etsitään id:llä oleva elementti
        sound.play(); //soitetaan ääni
    });

    $('.stop-sound').on('click', function () { //jos käyttäjä painaa fa-stop-circle-elementtiä

        //otetaan talteen käyttäjän tekemä valinta
        var soundId = this.id;
        var sound = document.getElementById(soundId); //etsitään id:llä oleva elementti
        sound.pause(); //pysäytetään äänen soitto
        sound.currentTime = 0; //alustetaan ääni soimaan alusta (ts. stop ei pause)
    });
});
