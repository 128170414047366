$(function() {

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });


    // iCheckbox.
    // -----------
    $('.icheck').iCheck({
        checkboxClass: 'icheckbox_square-blue',
        radioClass: 'iradio_square-blue',
        increaseArea: '20%'
    }).closest('.checkbox').css('visibility', 'visible');


    // Scroll to top control.
    // -----------------------
    $(window).scroll(function () {
        if ($(this).scrollTop() > 100) {
            $('#scroll-to-top').fadeIn();
        } else {
            $('#scroll-to-top').fadeOut();
        }
    });

    $('#scroll-to-top').click(function () {
        $("html, body").animate({
            scrollTop: 0
        }, 300);
        return false;
    });


    // Confirmation modal on Submit.
    // ------------------------------
    $('[data-submit-confirm-text]').click(function (e) {
        e.preventDefault();
        var $el = $(this);
        $('#modal-confirm-title').html($el.attr('data-submit-confirm-title'));
        $('#modal-confirm-body').html($el.attr('data-submit-confirm-text'));
        $('#modal-confirm')
            .modal({backdrop: 'static', keyboard: false})
            .one('click', '#confirmed', function (e) {
                $el.closest('form').submit();
            });
    });

    // Date picker
    // + input mask pv.kk.vvvv
    // ------------------------
    $('input[rel=datepicker]').daterangepicker({
        showWeekNumbers: true,
        format: 'YYYY-MM-DD',
        singleDatePicker: true,
        locale: {
            weekLabel: 'Vk.',
            daysOfWeek: ['Su', 'Ma', 'Ti', 'Ke', 'To', 'Pe', 'La'],
            monthNames: ['tammikuu', 'helmikuu', 'maaliskuu', 'huhtikuu', 'toukokuu', 'kesäkuu', 'heinäkuu', 'elokuu',
                'syyskuu', 'lokakuu', 'marraskuu', 'joulukuu'],
            firstDay: 1
        }
    });
});
