// Lisää taulukkoon raidoitukset (vaalea joka toinen rivi).
// Jos rivi on piilotettu, ei sitä muokata, vai näkyvissä olevat
// rivit raidoitetaan.
// -----------------------

/**
 * Lisää haluttuun taulukkoon raidoitukset niille riveille, jotka eivät
 * ole piilotettuina.
 *
 * @param table
 */
function addStripesToTable(table)
{
    var oddRow = false; //onko kyseessä pariton rivi

    //käydään koko taulu läpi
    for(var i = 0; i < table.rows.length; i++) {

        //jos rivi on piilotettu ei tehdä mitään
        if (table.rows[i].style.display == 'none') {

        //jos rivi on pariton (vaalea)
        } else if (oddRow) {

            oddRow = false; //seuraava rivi on parillinen
            table.rows[i].style.backgroundColor = '#f9f9f9'; //muutetaan vaaleaksi

        //jos rivi on parillinen (tumma)
        } else {

            oddRow = true; //seuraava rivi on pariton
            table.rows[i].style.backgroundColor = 'inherit';
        }

    }

}
