// Funktio, jolla halutulla id:llä oleva elementti saadaan näkyviin.
// Käytetään kuva/ääni-kysymyksissä, kun painetaan edit-painiketta
// tulee näkyviin tekstikenttä, jolla voidaan muokata kuvan arvoa.
// (onClick-event)
// -----------------------

function editPictureValue(id) {

    //etsitään id:llä oleva elementti
    var showElement = document.getElementById(id);

    //laitetaan elementti näkymään
    showElement.style.display = 'block';
}
