// Piilottaa likert-lisäkentät, jossa käyttäjän luoma
// kysymys ei ole likert-kysymys. Muussa tapauksesa näyttää.
// -----------------------

$(function() {

    //tarkistetaan löytyykö sivulta elementtiä id:llä answer_types
    //jos ei, ei tarvita likert-arvojen piilotusta/tarkisusta
    var element = document.getElementById('answer_types');
    if (element == null) {
        return;
    }

    //editoinnin yhteydessä jos valinta likert 1-7, niin näytetään myös tekstikentät
    var choice_id = $(".answer_types").val(); //katsotaan arvo

    //jos valinta on 3 eli likert 1-7, niin näytetään likert tekstikentät
    if(choice_id == 3) {
        $('#likert').show();
    } else {
        $('#likert').hide();
    }
});

    //katsoo mitä käyttäjä on valinnut valikosta
$('.answer_types').on('change', function (e) {

    var choice_id = e.target.value; //tallennetaan käyttäjän valinta

    //jos valinta on 3 eli likert 1-7, niin näytetään likert tekstikentät
    if(choice_id == 3) {
        $('#likert').show();
    } else {
        $('#likert').hide();
    }
});
